// @mui
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';
// import SelectField from 'src/custom_components/Froms/select';
import { OrderBox, boxIMG, palletIMG } from 'src/assets/added_icons';
import { useCallback, useEffect, useState } from 'react';
// routes
// import { useParams } from 'react-router-dom';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';

//
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import { debounce } from 'lodash';
import { useShelfActions, useShelfData } from 'src/context/providers/ShelfContext';
// import { useCustomerData } from 'src/context/providers/CustomerContext';
import OrderDrawerComponent from '../order/order-drawer-component';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const [alertData, setAlertData] = useState(null);
  const [orderIdAcceptance, setOrderIdAcceptance] = useState('');
  const settings = useSettingsContext();
  const inventorizedOrdersData = useShelfData()?.inventorizedOrders;
  // Custom
  // const tableData = useOrderData().orders;
  const [aRow, setARow] = useState({});
  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const { getInventarizationShelf, updateOrderInventarization, getInventorizedOrders } =
    useShelfActions();

  const clickedShelf = (e) => {};

  const closeAlert = () => {
    setAlertData(null);
  };

  const clickedBox = (e) => {};

  const [shelf, setShelf] = useState({
    id: '',
    tracking: '',
  });

  const [loadingShelf, setloadingShelf] = useState(false);
  const [shelfOptions, setShelfOptions] = useState([]);
  const fetchshelfes = useCallback(
    async (filters) => {
      setloadingShelf(true);
      try {
        const response = await getInventarizationShelf(filters);
        setShelfOptions(response?.shelves);
      } catch (error) {
        console.error(error);
      } finally {
        setloadingShelf(false);
      }
    },
    [getInventarizationShelf]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchshelfes(initialFilters);
  }, [fetchshelfes]);

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchshelfes(initialFilters);
  }, [fetchshelfes]);

  // Fetch shelf data based on input

  const handleShelfInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchshelfes(filters);
  }, 500);

  const updateOrder = () => {
    const filters = {
      tracking: shelf,
      take: 5,
    };
    if (orderIdAcceptance) {
      updateOrderInventarization(orderIdAcceptance, 'update')
        .then((data) => {
          if (data) {
            fetchshelfes(filters);
            getInventorizedOrders();
            setOrderIdAcceptance('');
          }
        })
        .catch((error) => {
          // console.error('Error updating order:', error);
        });
    }
  };

  const clear = () => {
    const filters = {
      tracking: shelf,
      take: 5,
    };
    updateOrderInventarization(null, 'clear').then((data) => {
      if (data) {
        fetchshelfes(filters);
        getInventorizedOrders();
        setOrderIdAcceptance('');
      }
    });
  };

  const shelfByID = shelfOptions?.filter((item) => item.id === shelf?.id);

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomBreadcrumbs heading="Orders" sx={{ padding: '0px 3%' }} />

          <Button
            variant="contained"
            onClick={clear}
            color="error"
            style={{ width: '15%' }}
            disabled={!inventorizedOrdersData?.length}
          >
            END INVENTARIZATION
          </Button>
        </div>
        <Box className="select_containers_large select_containers" sx={{ display: 'flex' }}>
          <div
            className="child_kanban"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <Box className="headings">
              <Typography variant="h6">
                Shelf {shelfByID[0]?.code ? shelfByID[0]?.code : ''}{' '}
              </Typography>
              <div className="number_of_item">
                {shelfByID[0]?.orders?.length ? shelfByID[0]?.orders?.length : 0}
              </div>
            </Box>
            <form
              className="little_input_boxes"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff', width: '45%' }
                  : { backgroundColor: '#161C24', width: '45%' }
              }
            >
              <Autocomplete
                sx={{ width: '100%' }}
                options={shelfOptions}
                getOptionLabel={(option) => option.code || ''}
                value={shelf}
                onChange={(event, newValue) => setShelf(newValue)}
                onInputChange={handleShelfInputChange}
                loading={loadingShelf}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Shelf Tracking"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingShelf ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {/* <SelectField
                widthComp="100%"
                selectName="shelf Tracking"
                data={shelfeData}
                setter={setShelfValue}
                selectedValue={shelf || null}
                name1="tracking"
              /> */}
            </form>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {shelfByID[0]?.orders?.length ? (
                  shelfByID[0].orders.map((i, index) => (
                    <Box
                      className="box_items"
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      key={i.id}
                      data-box-id={i.id}
                      onClick={clickedBox}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div className="light_text">
                          {i?.customer?.first_name} {i.customer?.last_name}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i?.customer?.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img src={palletIMG} alt="" width="20%" />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected shelf is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose desired shelf and start scanning items
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
          <div
            className="child_kanban_large"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <div
              className="little_input_boxes flex_col"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#161C24' }
              }
            >
              <TextField
                variant="filled"
                style={{ padding: '0px', width: '100%' }}
                name="orderIdAcceptance"
                label="Tracking"
                required
                value={orderIdAcceptance}
                onChange={(e) => setOrderIdAcceptance(e.target.value)}
                name1="code"
              />

              <Button
                variant="contained"
                onClick={updateOrder}
                color="success"
                style={{ width: '20%' }}
              >
                ADD
              </Button>
            </div>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {inventorizedOrdersData ? (
                  inventorizedOrdersData?.map((i, index) => (
                    <Box
                      className="box_items"
                      key={i.id}
                      data-shelf-id={i.id}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      onClick={clickedShelf}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div className="light_text">
                          {' '}
                          {i?.customer?.first_name} {i.customer?.last_name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i?.customer?.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img
                      src={boxIMG}
                      alt=""
                      width="20%"
                      // width="20%"
                    />
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </Box>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
