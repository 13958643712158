// ContentContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { toast } from 'react-toastify';
import { redirect } from 'react-router';

const ContentContext = createContext();
const ContentActionsContext = createContext();
const ContentDetailsContext = createContext();

export const ContentProvider = ({ children }) => {
  const [contentItems, setContentItems] = useState([]);
  const [shops, setShops] = useState([]);

  const [contentItemsUnique, setContentItemsUnique] = useState([]);
  const [contentImages, setContentImages] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);

  const [slots, setSlots] = useState([]);

  const createContentItem = async (newContentItem) => {
    try {
      const response = await apiService.post('/content-management', newContentItem, {
        headers: {
          timeout: 10000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getContentItems();
      getUniqueItems();
      toast.success('Content created!');
    } catch (error) {
      toast.error('Error creating content item:', error);
    }
  };

  const createShop = async (newShop) => {
    try {
      const response = await apiService.post('/shops', newShop, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getShops();
      toast.success('Content created!');
    } catch (error) {
      toast.error('Error creating content item:', error);
    }
  };

  const createNewRoles = async (newRoleItem) => {
    try {
      const response = await apiService.post('/admin/signup', newRoleItem, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getContentItems();
      getUniqueItems();
      toast.success('Role created!');
    } catch (error) {
      toast.error('Error creating new role:', error.message);
    }
  };

  const deleteContentItem = async (contentItemId) => {
    try {
      await apiService.delete(`/content-management/${contentItemId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setContentItems((prevItems) => prevItems.filter((item) => !contentItemId.includes(item.id)));
    } catch (error) {
      console.error('Error deleting content item:', error.message);
    }
  };

  const updateContentItem = async (contentItemId, updatedContentItem) => {
    try {
      const response = await apiService.patch(
        `/content-management/${contentItemId}`,
        updatedContentItem,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const data = await response.data;
      setContentItems((prevItems) =>
        prevItems.map((item) => (item.id === contentItemId ? { ...item, ...data } : item))
      );
    } catch (error) {
      toast.error('Error updating content item:', error);
    }
  };

  const getContentItems = async () => {
    try {
      const response = await apiService.get('/content-management', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setContentItems(data);
      return data;
    } catch (error) {
      console.error('Error fetching content items:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
      return error;
    }
  };

  const getShops = async () => {
    try {
      const response = await apiService.get('/shops', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setShops(data);
      return data;
    } catch (error) {
      console.error('Error fetching content items:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
      return error;
    }
  };

  const getUniqueItems = async () => {
    try {
      const response = await apiService.get('/content-management/uniques', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setContentItemsUnique(data);
      return data;
    } catch (error) {
      console.error('Error fetching content items:', error);
      return error;
    }
  };
  const getContentItemById = async (contentItemId) => {
    try {
      const response = await apiService.get(`/content-management/${contentItemId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedContent(data);
      return data;
    } catch (error) {
      console.error('Error fetching content item by ID:', error);
      throw error;
    }
  };

  const sendContentImage = async (imageFile, data) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append('file', file);
      });
      // eslint-disable-next-line
      const response = await apiService.post(`/s3/Content/image/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          data: JSON.stringify(data),
        },
      });
    } catch (error) {
      toast.error('Error updating content item:', error);
    }
  };

  const getContextImages = async () => {
    try {
      const response = await apiService.get('/content-management-img', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setContentImages(data);
      return data;
    } catch (error) {
      return error;
    }
  };

  const sendContentImageUpdate = async (imageFile, data) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append('file', file);
      });
      // eslint-disable-next-line
      const response = await apiService.post(`/s3/Content/image/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          data: JSON.stringify(data),
        },
      });
      getContentItems();
      getUniqueItems();
      getContextImages();
    } catch (error) {
      toast.error('Could not update');
    }
  };

  // Get all time slots
  const getAllTimeSlots = async () => {
    try {
      const response = await apiService.get('/time-slots', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSlots(data);
    } catch (error) {
      console.error('Error fetching time slots:', error);
      toast.error('Failed to fetch time slots');
    }
  };

  // Update cap for a specific time slot
  const updateTimeSlotCap = async (id, newCap) => {
    try {
      const response = await apiService.patch(
        `/time-slots/${id}`,
        { cap: newCap },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      await response.data;
      getAllTimeSlots();
      toast.success('Cap updated successfully!');
    } catch (error) {
      console.error('Error updating cap:', error);
      toast.error('Failed to update cap');
    }
  };

  useEffect(() => {
    getContentItems();
    getUniqueItems();
    getContextImages();
    getShops();
    getAllTimeSlots();
  }, []);

  const createContentItemCallback = useCallback(createContentItem, []);
  const createNewRolesCallback = useCallback(createNewRoles, []);
  const deleteContentItemCallback = useCallback(deleteContentItem, []);
  const updateContentItemCallback = useCallback(updateContentItem, []);
  const sendContentImageCallback = useCallback(sendContentImage, []);
  const sendContentImageUpdateCallback = useCallback(sendContentImageUpdate, []);
  const createShopCallback = useCallback(createShop, []);
  const updateTimeSlotCapCallbacks = useCallback(updateTimeSlotCap, []);
  const getAllTimeSlotsCallbacks = useCallback(getAllTimeSlots, []);
  const ContentActionsValue = useMemo(
    () => ({
      createContentItem: createContentItemCallback,
      deleteContentItem: deleteContentItemCallback,
      updateContentItem: updateContentItemCallback,
      getContentItemById,
      sendContentImage: sendContentImageCallback,
      sendContentImageUpdate: sendContentImageUpdateCallback,
      createNewRoles: createNewRolesCallback,
      createShop: createShopCallback,
      getAllTimeSlots: getAllTimeSlotsCallbacks,
      updateTimeSlotCap: updateTimeSlotCapCallbacks,
    }),
    [
      createContentItemCallback,
      deleteContentItemCallback,
      updateContentItemCallback,
      sendContentImageCallback,
      sendContentImageUpdateCallback,
      createNewRolesCallback,
      createShopCallback,
      getAllTimeSlotsCallbacks,
      updateTimeSlotCapCallbacks,
    ]
  );

  const ContentItemsValue = useMemo(
    () => ({
      contentItems,
      contentItemsUnique,
      contentImages,
      shops,
      slots,
    }),
    [contentItems, contentItemsUnique, contentImages, shops, slots]
  );

  return (
    <ContentContext.Provider value={ContentItemsValue}>
      <ContentActionsContext.Provider value={ContentActionsValue}>
        <ContentDetailsContext.Provider value={selectedContent}>
          {children}
        </ContentDetailsContext.Provider>
      </ContentActionsContext.Provider>
    </ContentContext.Provider>
  );
};

export const useContentData = () => useContext(ContentContext);
export const useContentActions = () => useContext(ContentActionsContext);
export const useContentDetails = () => useContext(ContentDetailsContext);

ContentProvider.propTypes = {
  children: PropTypes.element,
};
