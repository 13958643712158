import { useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import '../../styles/dashboard.scss';
import { useSettingsContext } from 'src/components/settings';
// import UploadImageComponent from 'src/custom_components/Froms/imageContentUpload';
import {
  Button,
  Container,
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Card,
} from '@mui/material';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  getComparator,
  useTable,
} from 'src/components/table';
import SelectField from 'src/custom_components/Froms/select';
import { _roles } from 'src/_mock';
import UserTableRow from 'src/custom_components/Table/customer-table-row';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { isEqual } from 'lodash';
import { IconButton } from 'yet-another-react-lightbox';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/custom_components/Table/helper/applyFilter';
import { useContentActions, useContentData } from 'src/context/providers/ContentContext';
import CreateFields from './components-creation';
import RoleCreation from './role-creation';
import { TABLE_HEAD, tableNames } from './view/data';
import UserTableToolbar from '../customer-address-details/customer-table-toolbar';
import { defaultFilters } from '../customer/view/data';
import UserTableFiltersResult from '../customer-address-details/customer-table-filters-result';
import CustomerDrawerComponentContent from './custom-drawer-component';

export default function ContentManagement() {
  const [pages, setPages] = useState();
  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);
  const [isOrderDrawerOpenRole, setIsOrderDrawerOpenRole] = useState(false);
  const table = useTable();
  const confirm = useBoolean();

  const [aRow, setARow] = useState({});
  // const [view, setView] = useState('Web');
  // const [files, setFiles] = useState([]);
  const settings = useSettingsContext();
  const [fieldToUpdate, setFieldToUpdate] = useState({
    nameKa: '',
    nameEn: '',
  });
  const [selectedWordsIndexes, setSelectedWordsIndexes] = useState([]);

  const [acordeonFieldsUpdate, setAcordeonFieldsUpdate] = useState({
    titleKa: '',
    titleEn: '',
    bodyKa: '',
    bodyEn: '',
  });

  const contetFieldData = useContentData()?.contentItems.filter(
    (item) => item.typeOfInput !== 'slider' && item.typeOfInput !== 'acordeon'
  );
  const tableData = pages
    ? contetFieldData.filter((item) => pages && item.page === pages.page)
    : contetFieldData;
  const swiperData = useContentData()?.contentItems.filter((item) => item.typeOfInput === 'slider');
  const shopsData = useContentData()?.shops;

  const acordeonData = useContentData()?.contentItems.filter(
    (item) => item.typeOfInput === 'acordeon'
  );

  const uniqueItems = useContentData()?.contentItemsUnique.filter((item) => item.page !== null);
  // const contentImages = useContentData()?.contentImages;
  const { updateContentItem, createContentItem, deleteContentItem, createShop, updateTimeSlotCap } =
    useContentActions();
  // const { sendContentImageUpdate } = useContentActions();
  const [swiperText, setswiperText] = useState({ ka: '', en: '' });
  const [shopText, setShopText] = useState('');

  const timeSlots = useContentData()?.slots;
  const [caps, setCaps] = useState({});

  // Handle cap changes for each individual time slot
  const handleCapChange = (slotId, value) => {
    setCaps((prevCaps) => ({ ...prevCaps, [slotId]: value }));
  };

  // Update cap for a specific slot using the stored cap value in local state
  const handleUpdateCap = (slotId) => {
    const newCap = caps[slotId];
    updateTimeSlotCap(slotId, newCap);
    setCaps((prevCaps) => ({ ...prevCaps, [slotId]: '' }));
  };

  const [acordeonText, setAcordeonText] = useState({
    titleKa: '',
    titleEn: '',
    bodyKa: '',
    bodyEn: '',
  });
  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAcordeon, setOpenDialogAcordeon] = useState(false);

  // const handleChange = (event, newValue) => {
  //   setView(newValue);
  // };

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const handleOrderDrawerToggle = () => {
    setIsOrderDrawerOpen(!isOrderDrawerOpen);
  };
  const handleOrderDrawerToggleRole = () => {
    setIsOrderDrawerOpenRole(!isOrderDrawerOpenRole);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const form = e.target;
  //   const values = {};
  //   for (let i = 0; i < form.elements.length; i += 1) {
  //     const element = form.elements[i];
  //     if (element.name) {
  //       values[element.name] = element.value;
  //     }
  //   }
  //   const elementID = e.target.id;
  //   updateContentItem(elementID, values);
  // };

  // const handleUploadImage = (contentImage) => {
  //   if (files.length > 0) {
  //     sendContentImageUpdate(files, contentImage);
  //     setFiles([]);
  //   }
  // };
  // const bearerToken = localStorage.getItem('token');

  const denseHeight = table.dense ? 52 : 72;
  const addNewSwiper = (e) => {
    e.preventDefault();
    const sliderAPIData = {
      typeOfInput: 'slider',
      selectNameType: 'Web',
      selectTextKa: swiperText.ka,
      selectTextEn: swiperText.en,
    };
    createContentItem(sliderAPIData);
  };

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const addWebsite = (e) => {
    e.preventDefault();
    const shopApiData = {
      shop: shopText,
    };
    createShop(shopApiData);
  };
  // const renderImageUpload = contentImages
  //   .filter((i) => i.selectNameType === view)
  //   .filter((i) => !pages || i.page === pages.page)
  //   .map((contentImage, index) => (
  //     <div key={index}>
  //       <div>{contentImage.identifier}</div>
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           justifyContent: 'space-between',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Image
  //           alt=""
  //           src={`${API_BASE_URL}/s3/image/${
  //             contentImage.name
  //           }?t=${new Date().getTime()}&token=${bearerToken}`}
  //           ratio="1/1"
  //           disabledEffect
  //           sx={{
  //             borderRadius: 1.25,
  //             m: 0.5,
  //             width: '60%',
  //             height: 100,
  //           }}
  //         />
  //         <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '30%' }}>
  //           <UploadImageComponent
  //             uniqueIdentifier={contentImage.id}
  //             files={files}
  //             setFiles={setFiles}
  //             hasPadding={false}
  //           />

  //           <Button
  //             variant="contained"
  //             onClick={() => handleUploadImage(contentImage)}
  //             style={{ width: '20%', height: '10%' }}
  //           >
  //             Update
  //           </Button>
  //         </Box>
  //       </Box>
  //     </div>
  //   ));
  const handleEdit = (item) => {
    setFieldToUpdate({
      id: item.id,
      nameKa: item.selectTextKa,
      nameEn: item.selectTextEn,
    });
    setOpenDialog(true);
  };
  const handleDelete = () => {
    setOpenDialog(false);
    deleteContentItem(fieldToUpdate.id);
  };

  const handleUpdate = () => {
    const valuesRoUpdate = {
      selectTextKa: fieldToUpdate.nameKa,
      selectTextEn: fieldToUpdate.nameEn,
      greenWords: selectedWordsIndexes,
    };
    updateContentItem(fieldToUpdate.id, valuesRoUpdate);
    setOpenDialog(false);
  };
  /// acordion functionality
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAcordeonText((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setARow({});
  };
  const submitAcordeon = (e) => {
    e.preventDefault();
    const insertValues = {
      typeOfInput: 'acordeon',
      selectNameType: 'Web',
      acordeonTitleKa: acordeonText.titleKa,
      acordeonTitleEN: acordeonText.titleEn,
      acordeonContentKa: acordeonText.bodyKa,
      acordeonContentEN: acordeonText.bodyEn,
    };
    createContentItem(insertValues);
    setAcordeonText({
      titleKa: '',
      titleEn: '',
      bodyKa: '',
      bodyEn: '',
    });
  };

  const editAcordeon = (item) => {
    setAcordeonFieldsUpdate({
      id: item.id,
      titleKa: item.acordeonTitleKa,
      titleEn: item.acordeonTitleEN,
      bodyKa: item.acordeonContentKa,
      bodyEn: item.acordeonContentEN,
    });
    setOpenDialogAcordeon(true);
  };

  const handleSave = () => {
    const updateFields = {
      acordeonTitleKa: acordeonFieldsUpdate.titleKa,
      acordeonTitleEN: acordeonFieldsUpdate.titleEn,
      acordeonContentKa: acordeonFieldsUpdate.bodyKa,
      acordeonContentEN: acordeonFieldsUpdate.bodyEn,
    };
    updateContentItem(acordeonFieldsUpdate.id, updateFields);

    setOpenDialogAcordeon(false);
    setAcordeonFieldsUpdate({ titleKa: '', titleEn: '', bodyKa: '', bodyEn: '' });
  };

  const handleCancel = () => {
    setOpenDialogAcordeon(false);
    setFieldToUpdate({ titleKa: '', titleEn: '', bodyKa: '', bodyEn: '' });
  };

  const handleAcordeonDelete = () => {
    deleteContentItem(acordeonFieldsUpdate.id);
    setOpenDialogAcordeon(false);
  };

  const handleWordSelect = (event) => {
    const selectedIndexes = event.target.value;
    setSelectedWordsIndexes(selectedIndexes);
  };

  // const handleResetFilters = useCallback(() => {
  //   setFilters(defaultFilters);
  // }, [defaultFilters]);
  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Content Management"
          action={
            <>
              <Button
                onClick={handleOrderDrawerToggleRole}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add Role
              </Button>

              <Button
                onClick={handleOrderDrawerToggle}
                variant="contained"
                sx={{ marginLeft: '10px' }}
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add Field
              </Button>
            </>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <CreateFields
          isDrawerOpen={isOrderDrawerOpen}
          handleDrawerToggle={handleOrderDrawerToggle}
        />
        <RoleCreation
          isDrawerOpen={isOrderDrawerOpenRole}
          handleDrawerToggle={handleOrderDrawerToggleRole}
        />
        <Box>
          <Typography variant="h5" gutterBottom>
            Time Slot Management
          </Typography>
          <Box sx={{ display: 'grid', gap: 3, mt: 2 }}>
            {timeSlots?.map((slot) => (
              <Box
                key={slot.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  borderRadius: 2,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'background.paper',
                  border: '1px solid',
                  borderColor: 'divider',
                  width: '40%',
                }}
              >
                <Typography variant="body1" sx={{ flex: 1, fontWeight: 500 }}>
                  {slot?.timeSlot}{' '}
                  <Typography component="span" sx={{ color: 'green', fontWeight: 600 }}>
                    - {slot?.cap}
                  </Typography>
                </Typography>

                <TextField
                  type="number"
                  value={caps[slot.id] || ''}
                  onChange={(e) => handleCapChange(slot.id, e.target.value)}
                  placeholder="New Cap"
                  variant="outlined"
                  size="small"
                  sx={{ width: 100 }}
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleUpdateCap(slot.id)}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 600,
                    backgroundColor: '#28a745',
                    '&:hover': { backgroundColor: '#218838' },
                  }}
                >
                  Update
                </Button>
              </Box>
            ))}
          </Box>
        </Box>

        {/* swiper create/update code  */}
        <Box
          sx={{
            margin: '30px 0px 50px',
          }}
        >
          <Typography gutterBottom className="subTitles">
            Shops
          </Typography>
          <form
            style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
            onSubmit={(e) => addWebsite(e)}
          >
            <TextField
              label="Text KA"
              variant="outlined"
              required
              value={shopText}
              onChange={(e) => setShopText(e.target.value)}
            />

            <Button variant="contained" color="primary" type="submit">
              ADD
            </Button>
          </form>
          <Box
            sx={{
              padding: '20px 20px 20px 0px',
              display: 'flex',
              flexDirection: 'column',
              height: '200px',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              gap: '5px',
              width: '40%',
            }}
          >
            {shopsData?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  border: '3px solid #F3F1EC',
                  padding: '10px',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <Typography> {i}</Typography>
              </Box>
            ))}

            <Dialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false);
                setSelectedWordsIndexes([]);
              }}
            >
              <DialogTitle>Edit Item</DialogTitle>
              <DialogContent>
                <TextField
                  label="Text Ka"
                  sx={{ marginTop: '10px' }}
                  value={fieldToUpdate.nameKa}
                  onChange={(e) => setFieldToUpdate({ ...fieldToUpdate, nameKa: e.target.value })}
                  fullWidth
                />
                <TextField
                  sx={{ marginTop: '10px' }}
                  label="Text En"
                  value={fieldToUpdate.nameEn}
                  onChange={(e) => setFieldToUpdate({ ...fieldToUpdate, nameEn: e.target.value })}
                  fullWidth
                />
                <FormControl sx={{ mt: 2, width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label">Select Words</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedWordsIndexes}
                    onChange={handleWordSelect}
                    input={<OutlinedInput label="Select Words" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {fieldToUpdate?.nameKa?.split(' ').map((word, index) => (
                      <MenuItem key={index} value={index}>
                        <Checkbox checked={selectedWordsIndexes.includes(index)} />
                        <ListItemText primary={word} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <Button onClick={handleDelete} color="error">
                  Delete
                </Button>
                <Button onClick={handleUpdate} color="primary">
                  Update
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        {/* swiper create/update code  */}

        {/* swiper create/update code  */}
        <Box
          sx={{
            margin: '30px 0px 50px',
          }}
        >
          <Typography gutterBottom className="subTitles">
            Swiper
          </Typography>
          <form
            style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
            onSubmit={(e) => addNewSwiper(e)}
          >
            <TextField
              label="Text KA"
              variant="outlined"
              required
              value={swiperText.ka}
              onChange={(e) => setswiperText({ ...swiperText, ka: e.target.value })}
            />
            <TextField
              label="Text EN"
              variant="outlined"
              value={swiperText.en}
              required
              onChange={(e) => setswiperText({ ...swiperText, en: e.target.value })}
            />
            <Button variant="contained" color="primary" type="submit">
              ADD
            </Button>
          </form>
          <Box
            sx={{
              padding: '20px 20px 20px 0px',
              display: 'flex',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              gap: '20px',
            }}
          >
            {swiperData?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  border: '3px solid #F3F1EC',
                  padding: '20px',
                  borderRadius: '24px',
                  gap: '20px',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => handleEdit(i)}
              >
                <Typography> {i.selectTextKa}</Typography>
                <Typography> {i.selectTextEn}</Typography>
              </Box>
            ))}

            <Dialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false);
                setSelectedWordsIndexes([]);
              }}
            >
              <DialogTitle>Edit Item</DialogTitle>
              <DialogContent>
                <TextField
                  label="Text Ka"
                  sx={{ marginTop: '10px' }}
                  value={fieldToUpdate.nameKa}
                  onChange={(e) => setFieldToUpdate({ ...fieldToUpdate, nameKa: e.target.value })}
                  fullWidth
                />
                <TextField
                  sx={{ marginTop: '10px' }}
                  label="Text En"
                  value={fieldToUpdate.nameEn}
                  onChange={(e) => setFieldToUpdate({ ...fieldToUpdate, nameEn: e.target.value })}
                  fullWidth
                />
                <FormControl sx={{ mt: 2, width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label">Select Words</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedWordsIndexes}
                    onChange={handleWordSelect}
                    input={<OutlinedInput label="Select Words" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {fieldToUpdate?.nameKa?.split(' ').map((word, index) => (
                      <MenuItem key={index} value={index}>
                        <Checkbox checked={selectedWordsIndexes.includes(index)} />
                        <ListItemText primary={word} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <Button onClick={handleDelete} color="error">
                  Delete
                </Button>
                <Button onClick={handleUpdate} color="primary">
                  Update
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        {/* swiper create/update code  */}

        {Object.keys(aRow).length > 0 && (
          <CustomerDrawerComponentContent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}

        {/* acordeon create/update code  */}
        <Box sx={{ margin: '30px 0px 50px' }}>
          <Typography gutterBottom className="subTitles">
            Accordion
          </Typography>
          <form
            onSubmit={submitAcordeon}
            style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <TextField
                type="text"
                name="titleKa"
                value={acordeonText.titleKa}
                onChange={handleInputChange}
                label="Title (Ka)"
                placeholder="Title (Ka)"
              />
              <TextField
                type="text"
                name="bodyKa"
                value={acordeonText.bodyKa}
                onChange={handleInputChange}
                label="Body (Ka)"
                placeholder="Body (Ka)"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <TextField
                type="text"
                name="titleEn"
                value={acordeonText.titleEn}
                onChange={handleInputChange}
                label="Title (En)"
                placeholder="Title (En)"
              />

              <TextField
                type="text"
                name="bodyEn"
                value={acordeonText.bodyEn}
                onChange={handleInputChange}
                label="Body (En)"
                placeholder="Body (En)"
              />
            </Box>

            <Button variant="contained" color="primary" type="submit">
              Add
            </Button>
          </form>

          {/* Edit dialog */}
          <Dialog open={openDialogAcordeon} onClose={handleCancel}>
            <DialogTitle>Edit Item</DialogTitle>
            <DialogContent>
              <TextField
                label="Title (Ka)"
                sx={{ margin: '10px 0px' }}
                value={acordeonFieldsUpdate.titleKa}
                onChange={(e) =>
                  setAcordeonFieldsUpdate({ ...acordeonFieldsUpdate, titleKa: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Title (En)"
                sx={{ margin: '10px 0px' }}
                value={acordeonFieldsUpdate.titleEn}
                onChange={(e) =>
                  setAcordeonFieldsUpdate({ ...acordeonFieldsUpdate, titleEn: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Body (Ka)"
                sx={{ margin: '10px 0px' }}
                value={acordeonFieldsUpdate.bodyKa}
                onChange={(e) =>
                  setAcordeonFieldsUpdate({ ...acordeonFieldsUpdate, bodyKa: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Body (En)"
                sx={{ margin: '10px 0px' }}
                value={acordeonFieldsUpdate.bodyEn}
                onChange={(e) =>
                  setAcordeonFieldsUpdate({ ...acordeonFieldsUpdate, bodyEn: e.target.value })
                }
                fullWidth
              />

              <DialogActions>
                <Button onClick={() => setOpenDialogAcordeon(false)}>Cancel</Button>
                <Button onClick={handleAcordeonDelete} color="error">
                  Delete
                </Button>
                <Button onClick={handleSave} color="primary">
                  UPDATE
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Box
            sx={{
              width: '70%',
              height: '300px',
              padding: '5px',
              overflowY: 'auto',
            }}
          >
            {acordeonData?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  border: '3px solid #F3F1EC',
                  borderRadius: '20px',
                  padding: '15px',
                  margin: '20px 0px 10px 0px',
                  cursor: 'pointer',
                }}
                onClick={() => editAcordeon(i)}
              >
                <div>{i.acordeonTitleKa}</div>
                <div>{i.acordeonContentKa}</div>
              </Box>
            ))}
          </Box>
        </Box>
        {/* acordeon create/update code  */}

        <Box>
          <SelectField
            selectName="Choose Page"
            data={uniqueItems}
            setter={setPages}
            selectedValue={pages || null}
            widthComp="20%"
            name1="page"
            showOrders={false}
          />
          {/* <Tabs
            value={view}
            onChange={handleChange}
            aria-label="icon label tabs example"
            sx={{ paddingTop: '20px' }}
          >
            <Tab icon={<ComputerIcon />} label="WEB" value="Web" />
            <Tab icon={<StayCurrentPortraitIcon />} label="MOBILE" value="Mobile" />
          </Tabs> */}

          {/* <Box className="content_management_box"> */}
          {/* <Box className="content_management_box_child">
              {pages
                ? contetFieldData
                    .filter((i) => i.page === pages?.page)
                    .filter((i) => i.selectNameType === view)
                    .map((dataObject, index) => (
                      <div key={index}>
                        <ContentInputField dataObject={dataObject} handleSubmit={handleSubmit} />
                      </div>
                    ))
                : contetFieldData
                    .filter((i) => i.selectNameType === view)
                    .map((dataObject, index) => (
                      <div key={index}>
                        <ContentInputField dataObject={dataObject} handleSubmit={handleSubmit} />
                      </div>
                    ))}
            </Box> */}

          {/* <Box className="content_management_box_child">
              {renderImageUpload}
              <Divider sx={{ borderStyle: 'dashed', padding: '10px' }} />
            </Box> */}

          <Card sx={{ mt: 2 }}>
            <UserTableToolbar
              filters={filters}
              onFilters={handleFilters}
              //
              roleOptions={_roles}
            />

            {canReset && (
              <UserTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                // onResetFilters={handleResetFilters}
                //
                results={dataFiltered.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <UserTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          // onDeleteRow={(e) => handleDeleteRow(e, row.id)}
                          // onEditRow={() => handleEditRow(row.id)}
                          handleDrawerToggle={handleDrawerToggle}
                          setARow={setARow}
                          tableNames={tableNames}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Box>
        {/* </Box> */}
      </Container>
    </div>
  );
}
