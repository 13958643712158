// @mui
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Card,
  Stack,
  Button,
  Divider,
  CardHeader,
  Typography,
  Modal,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
// components
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/config-global';
import UploadImage from 'src/custom_components/Froms/imageUpload';
import { useImageUploadActions } from 'src/context/providers/ImageUploadContext';
// import axios from 'axios';
import { useOrderActions } from 'src/context/providers/OrderContext';
import { API_BASE_URL, apiService } from 'src/utils/apiServices';
import Image from 'src/components/image';
import fileIcon from '../../assets/added_icons/dashboarIcons/file_img.svg';

// ----------------------------------------------------------------------

export default function OrderDetailsInfo({
  customer,
  delivery,
  payment,
  shippingAddress,
  aRow,
  formData,
  handleDrawerToggle,
  showFields = true,
}) {
  const GoToCustomerPage = () => {
    const url = `${PATH_DASHBOARD.customer.root}/${aRow.customer_id}`;
    window.open(url, '_blank');
    // window.location.href = `${PATH_DASHBOARD.customer.root}/${aRow.customer_id}`;
  };
  // images
  const [files, setFiles] = useState([]);
  const [signifyFiles, setSignifyFiles] = useState([]);
  const [declarationFile, setDeclarationFile] = useState([]);

  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedImagessignify, setDeletedImagessignify] = useState([]);
  const [documentDetails, setDocumentDetails] = useState({});
  const { sendImage, deleteImage, sendSignifyFile } = useImageUploadActions();
  const { updateDeclaration, createDeclarationDB } = useOrderActions();

  // declaration update modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);

  const [currentDeclaration, setCurrentDeclaration] = useState(null);
  const [updatedDeclaration, setUpdatedDeclaration] = useState({
    id: '',
    website: '',
    category: '',
    price: '',
  });

  const [createDeclaration, setCreateDeclaration] = useState({
    order_id: aRow.id,
    website: '',
    category: '',
    price: '',
  });
  const handleModalOpen = (declaration) => {
    setCurrentDeclaration(declaration);
    setUpdatedDeclaration({
      id: declaration.id,
      website: declaration.website,
      category: declaration.category,
      price: declaration.price,
    });
    setIsModalOpen(true);
  };

  const handleModalOpenCreate = () => {
    setIsModalOpenCreate(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentDeclaration(null);
    setUpdatedDeclaration({
      id: '',
      website: '',
      category: '',
      price: '',
    });
  };

  const handleModalCloseCreate = () => {
    setIsModalOpenCreate(false);
    // setCurrentDeclaration(null);
    setCreateDeclaration({
      id: '',
      website: '',
      category: '',
      price: '',
    });
  };

  const handleDeclarationChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDeclaration((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeclarationChangeCreate = (e) => {
    const { name, value } = e.target;
    setCreateDeclaration((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUpdateDeclaration = () => {
    updateDeclaration(updatedDeclaration.id, updatedDeclaration);

    const updatedDeclarations = aRow.declaration.map((decl) =>
      decl.id === currentDeclaration.id ? { ...decl, ...updatedDeclaration } : decl
    );
    // Here you would also send the updated declaration to your backend if needed.
    aRow.declaration = updatedDeclarations;
    setIsModalOpen(false);
  };

  const handleCreateDeclaration = () => {
    createDeclarationDB(createDeclaration, aRow.declaration.length)
      .then((newDeclaration) => {
        const updatedDeclarations = [...aRow.declaration, newDeclaration];
        aRow.declaration = updatedDeclarations;
        setIsModalOpenCreate(false);
        setCreateDeclaration({
          order_id: aRow.id,
          website: '',
          category: '',
          price: '',
        });
      })
      .catch((error) => {
        console.error('Error creating declaration:', error);
      });
  };
  useEffect(() => {
    const fetchSignifyStatus = async () => {
      if (aRow?.signify?.documentName) {
        try {
          const response = await apiService.get(`/signify/status/${aRow.signify.documentName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setDocumentDetails(response.data);
        } catch (error) {
          console.error('Error fetching signify status:', error);
          // Handle any errors that occur during the fetch here
        }
      } else {
        setDocumentDetails({});
      }
    };

    fetchSignifyStatus();
  }, [aRow]);

  useEffect(() => {
    const fetchSignifyFile = async () => {
      if (aRow?.signify?.documentName) {
        try {
          const response = await apiService.get(`/signify/download/${aRow.signify.documentName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setDeclarationFile(response.data.documents[0]);
        } catch (error) {
          console.error('Error fetching signify status:', error);
          // Handle any errors that occur during the fetch here
        }
      } else {
        setDeclarationFile({});
      }
    };

    fetchSignifyFile();
  }, [aRow]);
  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${declarationFile?.base64}`;
    link.download = 'filename.pdf'; // Name the download file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadImage = () => {
    if (files.length > 0) {
      sendImage(files, aRow.id);
      handleDrawerToggle();
    }
    if (deletedImages.length > 0) {
      deleteImage(deletedImages);
      handleDrawerToggle();
    }
  };
  const handleSignifyUpload = () => {
    if (signifyFiles.length > 0) {
      sendSignifyFile(signifyFiles, aRow.id);
      handleDrawerToggle();
    }
  };

  const bearerToken = localStorage.getItem('token');

  const renderCustomer = (
    <>
      <CardHeader
        title="Customer Info"
        // action={
        //   <IconButton>
        //     <Iconify icon="solar:pen-bold" />
        //   </IconButton>
        // }
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle1">
            {aRow?.customer?.first_name} {aRow?.customer?.last_name}
          </Typography>

          <Box sx={{ color: 'text.secondary' }}>{aRow?.customer?.email}</Box>
          <Box sx={{ color: 'text.secondary' }}>{aRow?.customer?.phone}</Box>

          <Button
            size="small"
            color="primary"
            startIcon={<Iconify icon="mingcute:add-line" />}
            sx={{ mt: 1 }}
            onClick={GoToCustomerPage}
          >
            Go to B2B Customer Details
          </Button>
        </Stack>
      </Stack>
    </>
  );

  const renderDelivery = (
    <>
      <CardHeader title="Delivery" />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Address
          </Box>
          {aRow?.orderDetail?.address}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Instructions
          </Box>
          {aRow?.orderDetail?.additionalInstructions} {aRow?.orderDetail?.tag}{' '}
          {aRow?.orderDetail?.title} /{' '}
          {aRow?.orderDetail?.tbilisiRegion === 'tb' ? 'Tbilisi' : 'Region'}
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ color: '#FFAB00' }}>
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Time Slots
          </Box>
          {formData.timeSlot}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Room Number
          </Box>
          <Link underline="always" color="inherit">
            {formData.room_number}
          </Link>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Order Date
          </Box>
          {formData?.order_date?.split(' at ')[0]}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Do not ring the bell
          </Box>
          {aRow?.do_not_ring_bell ? 'YES' : 'NO'}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Leave by door
          </Box>
          {aRow?.leave_by_door ? 'YES' : 'NO'}
        </Stack>
      </Stack>
    </>
  );
  const renderQS = (
    <>
      <CardHeader title="QuickShipper" />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 400, flexShrink: 0 }}>
            internalID
          </Box>
          {aRow?.quickshipper?.internalID}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 400, flexShrink: 0 }}>
            QS ID
          </Box>
          {aRow?.quickshipper?.externalID}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 400, flexShrink: 0 }}>
            Zone
          </Box>
          {aRow?.quickshipper?.comment}
        </Stack>
      </Stack>
    </>
  );

  const renderDeclaration = (
    <>
      <CardHeader title="Declaration" />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        {aRow?.declaration?.map((i, index) => (
          <Stack
            direction="row"
            alignItems="center"
            key={i.id}
            sx={{ justifyContent: 'space-between' }}
          >
            <Box component="span" sx={{ color: 'text.primary', mr: 2 }}>
              {i?.website} - {i?.category}
            </Box>
            <Box>
              <Link underline="always" color="inherit" sx={{ mr: 2 }}>
                {i.price}$
              </Link>
              <Button size="small" onClick={() => handleModalOpen(i)} variant="outlined">
                Update
              </Button>
            </Box>
          </Stack>
        ))}
        <Divider sx={{ borderStyle: 'solid', width: '20%' }} />
        <Box component="span" sx={{ color: 'text.primary', mr: 2 }}>
          Total - {aRow?.declaration?.reduce((acc, curr) => acc + parseFloat(curr.price), 0)}$
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => handleModalOpenCreate()}
          >
            Add Declaration
          </Button>
        </Box>
      </Stack>
    </>
  );

  // const renderShipping = (
  //   <>
  //     <CardHeader title="Shipping" />
  //     <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
  //       <Stack direction="row" alignItems="center" sx={{ color: 'green' }}>
  //         <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
  //           Address
  //         </Box>
  //         {aRow?.customer?.georgian_main_address}
  //       </Stack>
  //       <Stack direction="row" alignItems="center">
  //         <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
  //           Phone number
  //         </Box>
  //         {aRow?.customer?.phone}
  //       </Stack>
  //     </Stack>
  //   </>
  // );

  const renderNusxa = (
    <>
      <CardHeader title="Nusxa" />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center" sx={{ color: 'green' }}>
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            number
          </Box>
          {aRow?.nusxa}
        </Stack>
      </Stack>
    </>
  );

  // const renderPayment = (
  //   <>
  //     <CardHeader title="Cards" />
  //     {card?.map((item) => (
  //       <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }} key={item.id}>
  //         <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
  //           Card {item?.cardType === 'mc' ? 'MasterCard' : 'Visa'}
  //         </Box>

  //         {item?.mask}
  //         <Iconify
  //           icon={item?.cardType === 'mc' ? 'logos:mastercard' : 'logos:visa'}
  //           width={24}
  //           sx={{ ml: 0.5 }}
  //         />
  //       </Stack>
  //     ))}
  //   </>
  // );
  const renderImageUpload = (
    <>
      <CardHeader title="Upload Image" />
      <Stack sx={{ pb: 3 }}>
        <UploadImage
          row={aRow}
          files={files}
          setFiles={setFiles}
          deletedImages={deletedImages}
          setDeletedImages={setDeletedImages}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '10px',
          }}
        >
          <Button variant="contained" endIcon={<UploadIcon />} onClick={handleUploadImage}>
            Upload
          </Button>
        </Box>
      </Stack>
    </>
  );

  const renderUserDeclarationFiles = (
    <>
      <CardHeader title="Uploaded declarations" />
      <Box sx={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
        {aRow?.declarationFiles?.map((i) => (
          <div key={i.id} style={{ position: 'relative' }}>
            <a
              href={`${API_BASE_URL}/s3/image/${i.name}?token=${bearerToken}`}
              download={i.name}
              style={{
                color: 'white',
              }}
              target="blank"
            >
              <Image
                key={i.id}
                src={fileIcon}
                ratio="1/1"
                disabledEffect
                sx={{
                  borderRadius: 1.25,
                  m: 2,
                  width: 50,
                  height: 50,
                }}
              />
            </a>
          </div>
        ))}
      </Box>
    </>
  );
  const renderSignifyUpload = (
    <>
      <CardHeader title="Upload Signify File" />
      <Stack sx={{ pb: 3 }}>
        <UploadImage
          row={aRow}
          fileType="file"
          files={signifyFiles}
          setFiles={setSignifyFiles}
          deletedImages={deletedImagessignify}
          setDeletedImages={setDeletedImagessignify}
        />

        <Typography variant="subtitle2" sx={{ p: 3 }}>
          {documentDetails &&
          documentDetails.documentsStatus &&
          Array.isArray(documentDetails.documentsStatus) &&
          documentDetails.documentsStatus.length > 0 &&
          documentDetails.documentsStatus[0].recipientsStatus &&
          Array.isArray(documentDetails.documentsStatus[0].recipientsStatus) &&
          documentDetails.documentsStatus[0].recipientsStatus.length > 0 &&
          documentDetails.documentsStatus[0].recipientsStatus[0].status
            ? documentDetails.documentsStatus[0].recipientsStatus[0].status
            : ''}
        </Typography>
        {declarationFile?.base64 && (
          <Button onClick={downloadPdf} sx={{ width: '20%', ml: 2 }} variant="contained">
            Download PDF
          </Button>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '10px',
          }}
        >
          <Button variant="contained" endIcon={<UploadIcon />} onClick={handleSignifyUpload}>
            Upload
          </Button>
        </Box>
      </Stack>
    </>
  );

  return (
    <Card>
      {renderCustomer}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {renderDelivery}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {aRow?.quickshipper ? renderQS : ''}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {renderDeclaration}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {renderUserDeclarationFiles}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {showFields ? renderNusxa : ''}

      {/* <Divider sx={{ borderStyle: 'dashed' }} />

      {showFields ? renderPayment : ''} */}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {showFields ? renderImageUpload : ''}

      <Divider sx={{ borderStyle: 'dashed' }} />
      {showFields ? renderSignifyUpload : ''}

      <Modal open={isModalOpen} onClose={handleModalClose} sx={{ display: 'flex' }}>
        <Box
          sx={{ width: 400, p: 3, bgcolor: 'background.paper', borderRadius: 1, margin: 'auto' }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Update Declaration
          </Typography>
          <TextField
            fullWidth
            label="Website"
            name="website"
            variant="outlined"
            value={updatedDeclaration.website}
            onChange={handleDeclarationChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Category"
            name="category"
            variant="outlined"
            value={updatedDeclaration.category}
            onChange={handleDeclarationChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Price"
            name="price"
            variant="outlined"
            value={updatedDeclaration.price}
            onChange={handleDeclarationChange}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleUpdateDeclaration}>
            Update
          </Button>
        </Box>
      </Modal>

      <Modal open={isModalOpenCreate} onClose={handleModalCloseCreate} sx={{ display: 'flex' }}>
        <Box
          sx={{ width: 400, p: 3, bgcolor: 'background.paper', borderRadius: 1, margin: 'auto' }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Create Declaration
          </Typography>
          <TextField
            fullWidth
            label="Website"
            name="website"
            variant="outlined"
            value={createDeclaration.website}
            onChange={handleDeclarationChangeCreate}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Category"
            name="category"
            variant="outlined"
            value={createDeclaration.category}
            onChange={handleDeclarationChangeCreate}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Price"
            name="price"
            variant="outlined"
            value={createDeclaration.price}
            onChange={handleDeclarationChangeCreate}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleCreateDeclaration}>
            Create
          </Button>
        </Box>
      </Modal>
    </Card>
  );
}

OrderDetailsInfo.propTypes = {
  customer: PropTypes.object,
  delivery: PropTypes.object,
  payment: PropTypes.object,
  shippingAddress: PropTypes.object,
  aRow: PropTypes.object,
  formData: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  showFields: PropTypes.bool,
};
