import { Helmet } from 'react-helmet-async';
import { InventarizationListView } from 'src/sections/inventarization/view';

export default function InventarizationListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: inventarization</title>
      </Helmet>

      <InventarizationListView />
    </>
  );
}
